import React, { useState, useRef, useContext } from 'react'
import UpdateMobileDialog from 'components/Person/UpdateMobileDialog'
import EditPositionDialog from 'components/Person/EditPositionDialog'
import EditUserNameDialog from 'components/Person/EditUserNameDialog'
import UpdateAvatarDialog from 'components/Person/UpdateAvatarDialog'
import { providerContext } from 'components/Provider'
import Seo from 'components/seo'
import SvgIcon from 'components/SvgIcon'
import Layout from 'layouts/zh'
import { updateUsers, updateAvatar } from 'api/user'

const INDUSTRY_OPTIONS = [
  { value: '证券基金', label: '证券基金' },
  { value: '品牌零售', label: '品牌零售' },
  { value: '融合媒体', label: '融合媒体' },
  { value: '企业服务', label: '企业服务' },
  { value: '在线教育', label: '在线教育' },
  { value: '电子商务', label: '电子商务' },
  { value: '本地生活', label: '本地生活' },
  { value: '工具软件', label: '工具软件' },
  { value: '渠道合作', label: '渠道合作' },
  { value: '互联网金融', label: '互联网金融' },
  { value: '银行', label: '银行' },
  { value: '保险', label: '保险' },
  { value: '汽车', label: '汽车' },
  { value: '社交', label: '社交' },
  { value: '文娱', label: '文娱' },
  { value: '游戏', label: '游戏' },
  { value: '其他', label: '其他' },
]

import * as styles from './index.module.less'

const UserCenter = (props: any) => {
  const [visibleUserName, setVisibleUserName] = useState(false)
  const [visibleMobile, setVisibleMobile] = useState(false)
  const [visiblePosition, setVisiblePosition] = useState(false)
  const [isUserUpdate, setIsUserUpdate] = useState<boolean>(false)
  const [visibleAvatar, setVisibleAvatar] = useState(false)

  // 修改头像
  const onAvatarConfirm = (image: string) => {
    updateAvatar({
      data: image,
    }).then(
      (res) => {
        setVisibleAvatar(false)
        setIsUserUpdate(!isUserUpdate)
      },
      (e) => {
        console.log('updateAvatar=======', e.response)
      },
    )
  }

  // 修改姓名
  const onUserNameConfirm = (userName?: string) => {
    updateUsers({
      userName,
    }).then(
      (res) => {
        setVisibleUserName(false)
        setIsUserUpdate(!isUserUpdate)
      },
      (e) => {
        console.log('updateAvatar=======', e.response)
      },
    )
  }

  // 修改职业信息
  const onPositionConfirm = (group_name?: string, position?: string, industry?: string) => {
    updateUsers({
      companyName: group_name,
      position,
      industry,
    }).then(
      (res) => {
        setVisiblePosition(false)
        setIsUserUpdate(!isUserUpdate)
      },
      (e) => {
        console.log('updateAvatar=======', e.response)
      },
    )
  }

  return (
    <Layout footerClassName={styles.FooterContainer} userUpdate={isUserUpdate}>
      <Seo title="个人主页" description="" keywords="" saTitle=""></Seo>
      <main className={styles.user_center}>
        <UserWrap
          onClickUserName={() => setVisibleUserName(true)}
          onClickPosition={() => setVisiblePosition(true)}
          onClickMobile={() => setVisibleMobile(true)}
          onClickAvatar={() => setVisibleAvatar(true)}
        />
      </main>
      {/* 您新头像的位置和大小 */}
      <UpdateAvatarDialog visible={visibleAvatar} onClose={() => setVisibleAvatar(false)} onConfirm={onAvatarConfirm} />
      {/* 编辑我的姓名 */}
      <EditUserNameDialog
        visible={visibleUserName}
        onClose={() => setVisibleUserName(false)}
        onSubmit={onUserNameConfirm}
      />
      <UpdateMobileDialog
        visible={visibleMobile}
        onClose={() => setVisibleMobile(false)}
        onSuccess={() => {
          setVisibleMobile(false)
          setIsUserUpdate(!isUserUpdate)
        }}
      />
      <EditPositionDialog
        visible={visiblePosition}
        onClose={() => setVisiblePosition(false)}
        onSubmit={onPositionConfirm}
        industryOptions={INDUSTRY_OPTIONS as { value: string; label: string }[]}
      />
    </Layout>
  )
}

export default UserCenter

interface UserWrapProps {
  onClickUserName?: () => void
  onClickPosition?: () => void
  onClickMobile?: () => void
  onClickAvatar?: () => void
}

const UserWrap: React.FC<UserWrapProps> = (props) => {
  const { onClickUserName, onClickPosition, onClickMobile, onClickAvatar } = props
  const { user: userInfo } = useContext(providerContext)
  const [isUpload, setIsUpload] = useState<boolean>()

  const handleFileChange = (e: any) => {
    // const file = e.target.files[0];
    // dispatch({ uploadFile: file, showCroppingToast: true });
  }

  const setPhone = (num: any) => {
    return (num && num.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')) || ''
  }

  return (
    <div className="user-wrap">
      <dl className="user-cont">
        <dt className={`user-photo ${userInfo?.user_avatar_url ? '' : 'bg'}`} onClick={onClickAvatar}>
          {userInfo?.user_avatar_url ? (
            <img src={userInfo?.user_avatar_url as string} alt="头像" />
          ) : (
            <SvgIcon name="photo" width="47" height="34" />
          )}
        </dt>
        <dd className="user-info">
          <p className="name-wrap">
            <span className="input-editor">
              <span className="input-place">{userInfo?.user_name}</span>
              <span className="editor" onClick={onClickUserName}>
                <SvgIcon name="editor" width="16" height="16" />
              </span>
            </span>
          </p>
          {userInfo?.email && (
            <p className="user-email">
              <SvgIcon name="email" width="16" height="16" />
              <span className="email">{userInfo?.email}</span>
            </p>
          )}
        </dd>
      </dl>
      <ul className="info-classify">
        <li className="position" onClick={onClickPosition}>
          <span className="title">职位</span>
          <span className="value">
            <span className="cont">{userInfo?.position || '请输入您的职位'}</span>
            <SvgIcon width="8" height="12" name="right_arrow" />
          </span>
        </li>
        <li className="group-name" onClick={onClickPosition}>
          <span className="title">所在公司</span>
          <span className="value">
            <span className="cont">{userInfo?.group_name || '请输入您所在公司'}</span>
            <SvgIcon width="8" height="12" name="right_arrow" />
          </span>
        </li>
        <li className="industry" onClick={onClickPosition}>
          <span className="title">所在行业</span>
          <span className="value">
            <span className="cont">{userInfo?.industry || '请选择您所在行业'}</span>
            <SvgIcon width="8" height="12" name="right_arrow" />
          </span>
        </li>
      </ul>
      <ul className="info-classify">
        <li className="phone" onClick={onClickMobile}>
          <span className="title">手机号</span>
          <span className="value">
            <span className="cont">{setPhone(userInfo?.phone)}</span>
            <SvgIcon width="8" height="12" name="right_arrow" />
          </span>
        </li>
      </ul>
    </div>
  )
}
