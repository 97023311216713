import React from 'react';
import Svg from 'react-inlinesvg';

interface SvgIconProps {
  name?: string;
  width?: number | string; 
  height?: number | string;
  className?: string;
  onClick?: () => void;
}

const SvgIcon: React.FC<SvgIconProps> = (props) => {
  const { name, width, height, className, onClick } = props;
  const url = require(`../../assets/svgs/${name}.svg`).default;
  const handleClick = () => {
    return typeof onClick === 'function' ? onClick() : false;
  };
  return (
    <Svg
      src={url}
      width={width}
      height={height}
      className={className}
      onClick={handleClick}
    />
  );
};

export default SvgIcon;
